import React from 'react';
import Pagination from 'src/components/Pagination';
import { number } from 'prop-types';

export function BackendPaginationWrapper(props) {
    const { totalRows } = props;

    return totalRows > 0 ? <Pagination {...props} /> : null;
}

BackendPaginationWrapper.propTypes = {
    totalRows: number
};
