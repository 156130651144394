import React from 'react';
import DollarSign from 'feather/dollar-sign';

export const projectStatuses = {
    all: '0',
    has: '1',
    doesNotHave: '2',
    pending: '3'
};

// `possibleStatuses` order matters - we cycle through these when the icon is clicked
export const commissionSearchType = {
    value: 'commissionSearchType',
    status: projectStatuses.all,
    possibleStatuses: [
        projectStatuses.all,
        projectStatuses.has,
        projectStatuses.pending,
        projectStatuses.doesNotHave
    ],
    label: 'Has commission',
    labelPending: 'Pending commission',
    labelFalse: 'No commission',
    labelNone: 'Filter by commission',
    icon: <DollarSign />
};

export default [commissionSearchType];
