import navigation from 'src/util/navigation';
import urlParamOptions from 'src/constants/urlParamOptions';
import invoiceFilters, { filterStatuses } from 'src/constants/invoiceFilters';
import projectFilters from 'src/constants/projectFilters';
import getHeaderString from 'src/util/getHeaderString';
export default class QueryParams {
    _query = {};

    constructor(query) {
        this.query = query;
    }

    get isEmpty() {
        return Object.keys(this.query) > 0;
    }

    set query(newQuery) {
        this._query = newQuery;
    }

    get pageNumber() {
        return this._query.pageNumber ? parseInt(this._query.pageNumber) : 1;
    }

    get query() {
        return this._query;
    }

    get startDate() {
        return this._query.startDate;
    }

    get activeDate() {
        return this._query.activeDate;
    }

    get dateSearchOption() {
        return this._query.dateSearchOption;
    }

    get rollingDateOption() {
        return this._query.rollingDateOption;
    }

    get resultsPerPage() {
        return parseInt(this._query.resultsPerPage) || null;
    }

    get endDate() {
        return this._query.endDate;
    }

    get sort() {
        return {
            sort: this.query.sort,
            asc: this.query.asc === 'true' || this.query.asc === true
        };
    }

    get filter() {
        return this._query.filter
            ? parseInt(this._query.filter) || this._query.filter
            : undefined;
    }

    get selectedAnalysis() {
        return this._query.selectedAnalysis
            ? this._query.selectedAnalysis
            : undefined;
    }

    get headers() {
        return this._query.headers ? this._query.headers.split(',') : [];
    }

    get payPeriods() {
        return this._query.payPeriods ? this._query.payPeriods : undefined;
    }

    get invoiceFilters() {
        const foundFilters = invoiceFilters.map(filter => {
            const foundFilterStatus = this.query[filter.value];
            if (foundFilterStatus) {
                filter.status = foundFilterStatus;
            } else {
                filter.status = filterStatuses.all;
            }
            return filter;
        });
        return foundFilters;
    }

    get projectFilters() {
        const foundFilters = projectFilters.map(filter => {
            const foundFilterStatus = this.query[filter.value];
            if (foundFilterStatus) {
                filter.status = foundFilterStatus;
            } else {
                filter.status = filterStatuses.all;
            }
            return filter;
        });
        return foundFilters;
    }

    get scrollId() {
        return this._query.scrollId
            ? parseInt(this._query.scrollId)
            : undefined;
    }

    getColumnSearch(headers) {
        // Here we use the table headers to find any possible urlParams
        // Then we create a new array containing all the the searchTerm and id combos
        const params = {};
        headers.forEach(header => {
            const searchParam = `${header.value}Search`;
            const optionParam = `${header.value}Option`;
            if (this.query[searchParam]) {
                let search = this.query[searchParam];
                params[searchParam] = search;
            }
            if (this.query[optionParam]) {
                let option = this.query[optionParam];
                params[optionParam] = option;
            }
        });

        return params;
    }

    setURLParams(replace = false) {
        if (navigation) {
            const url = navigation.lastRoute.url;

            url.query = this.query;

            navigation.navigate(
                {
                    pathname: url.pathname,
                    query: url.query
                },
                {
                    replace: replace
                }
            );
        }
    }

    // Set sort params when new sort is selected
    setSortParams(sort, asc) {
        this.query = { ...this.query, sort: sort, asc: asc };
        return this;
    }

    setBeneficiaryType(beneficiaryType) {
        this.query = { ...this.query, beneficiaryType: beneficiaryType };
        return this;
    }

    setTreeType(treeType) {
        this.query = { ...this.query, treeType: treeType };
        return this;
    }

    setSearchBarParams(term) {
        const newParams = { ...this.query };
        if (term) {
            newParams.search = term;
            this.query = newParams;
        } else {
            delete newParams.search;
            this.query = newParams;
        }
        return this;
    }

    setPaginationParams(pageNumber) {
        pageNumber = parseInt(pageNumber);
        const newParams = { ...this.query, pageNumber: pageNumber };
        const url = navigation.lastRoute.url;
        //If previous route has no specified page, then replace in history stack
        if (pageNumber && pageNumber !== url.query.pageNumber) {
            this.query = { ...this.query, ...newParams };
        }
        return this;
    }

    setFilterParams(filter) {
        this.query = { ...this.query, filter: filter };
        return this;
    }

    setSelectedAnalysis(analysis) {
        this.query = { ...this.query, selectedAnalysis: analysis };
        return this;
    }

    setMultiSelectSearchParams(selectedItems) {
        const values = selectedItems.map(item => {
            return item.value;
        });

        if (selectedItems.length === 0) {
            delete this.query.searchParams;
            this.query = { ...this.query };
        } else {
            this.query = { ...this.query, searchParams: values.join() };
        }
        return this;
    }

    setInvoiceFilterParams(selectedItems) {
        const newQuery = { ...this.query };
        // Assign filter statuses - if the status is 'none', remove from query altogether
        selectedItems.forEach(filter => {
            if (filter.status !== filterStatuses.all) {
                newQuery[filter.value] = filter.status;
            } else {
                delete newQuery[filter.value];
            }
        });
        this.query = newQuery;
        return this;
    }

    setProjectFilterParams(selectedItems) {
        const newQuery = { ...this.query };

        selectedItems.forEach(filter => {
            if (filter.status !== filterStatuses.all) {
                newQuery[filter.value] = filter.status;
            } else {
                delete newQuery[filter.value];
            }
        });
        this.query = newQuery;
        return this;
    }

    setColumnSearchParam(header, searchTerm) {
        let newParams = { ...this.query };
        if (searchTerm) {
            newParams[`${header.value}${urlParamOptions.search}`] = searchTerm;
            this.query = newParams;
        } else {
            delete newParams[`${header.value}${urlParamOptions.search}`];
            this.query = newParams;
        }
        return this;
    }

    setColumnComparatorParams(header) {
        const newParams = { ...this.query };
        if (header.selectedSearchOption) {
            newParams[`${header.value}${urlParamOptions.option}`] =
                header.selectedSearchOption;
            this.query = newParams;
        }
        return this;
    }

    // Set date params when new date range is selected
    setDateParams(dateRange) {
        if (!dateRange.startDate || !dateRange.endDate) {
            // eslint-disable-next-line no-console
            console.warn(
                'dateRange argument is empty, setting to default moment return'
            );
        }
        this.query = { ...this.query, ...dateRange };
        return this;
    }

    setActiveDateParam(activeDate) {
        this.query = { ...this.query, activeDate };
        return this;
    }

    // Set date search param - for example, search by invoice paid date instead of creation date
    setDateSearchOptionParam(option) {
        this.query = { ...this.query, dateSearchOption: option };
        return this;
    }

    // Set rolling date option, for sharing/bookmarking queries
    setRollingDateOption(option) {
        this.query = { ...this.query, rollingDateOption: option };
        return this;
    }

    // Set results per page
    setResultsPerPageParam(resultsPerPage) {
        this.query = { ...this.query, resultsPerPage: resultsPerPage };
        return this;
    }

    // Set optional headers
    // Try adding all headers to single key w/ comma-separated string
    setHeaders(headers) {
        const newParams = { ...this.query };
        const headerString = getHeaderString(headers);
        this.query = { ...newParams, headers: headerString };
        return this;
    }

    setPayPeriods(payPeriods) {
        if (payPeriods) this.query = { ...this.query, payPeriods: payPeriods };
        else delete this.query.payPeriods;
        return this;
    }
}
