import React from 'react';
import classes from './pagination.module.css';
import ChevronLeft from 'react-feather/dist/icons/chevron-left';
import ChevronRight from 'react-feather/dist/icons/chevron-right';
import { number, func } from 'prop-types';

const iconSize = '1.75rem';

function Pagination(props) {
    const defaultPageSize = 15;
    const {
        totalRows,
        currentPage = 1,
        handlePagination,
        pageSize = defaultPageSize
    } = props;

    const leftActive = currentPage > 1;
    const rightActive = totalRows === pageSize;

    const leftNavClass = leftActive
        ? classes.navButton_active
        : classes.navButton;
    const rightNavClass = rightActive
        ? classes.navButton_active
        : classes.navButton;

    function moveLeft() {
        if (leftActive) moveToPage(currentPage - 1);
    }

    function moveRight() {
        if (rightActive) moveToPage(currentPage + 1);
    }

    function moveToPage(page) {
        handlePagination(page);
    }

    function getPageIndex() {
        const firstRow = pageSize * (currentPage - 1) + 1;
        const lastRow = pageSize * (currentPage - 1) + totalRows;

        if (lastRow)
            return (
                <div>
                    {firstRow} - {lastRow} of {rightActive ? 'many' : lastRow}
                </div>
            );
    }

    return (
        <div className={classes.root}>
            <span className={classes.index}>{getPageIndex()}</span>
            <span className={classes.nav}>
                <button className={leftNavClass} onClick={moveLeft}>
                    <ChevronLeft size={iconSize} />
                </button>
                <button className={rightNavClass} onClick={moveRight}>
                    <ChevronRight size={iconSize} />
                </button>
            </span>
        </div>
    );
}

Pagination.propTypes = {
    totalRows: number,
    currentPage: number,
    handlePagination: func,
    pageSize: number
};

export default Pagination;
