import React from 'react';
import { Pagination } from 'fronds/Components';
import { number } from 'prop-types';

function PaginationWrapper(props) {
    const { totalRows } = props;

    return totalRows > 0 ? <Pagination {...props} /> : null;
}

PaginationWrapper.propTypes = {
    totalRows: number
};

export default PaginationWrapper;
